<template>
  <div>{{ currency(truncateToDecimals(value)) }}</div>
</template>

<script>
import currencyFormatter from "currency-formatter";

export default {
  props: ["value", "precision"],
  methods: {
    currency(num) {
      const d = num.toString().split(".")[1];
      const precision = d ? (d.length > 2 ? 2 : d.length) : 0;
      return currencyFormatter.format(num, { code: "USD", precision });
    },
    truncateToDecimals(num, dec = 2) {
      const calcDec = Math.pow(10, dec);
      return Math.trunc(num * calcDec) / calcDec;
    }
  }
};
</script>

<style></style>
